import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import useDeviceSize from "../hooks/use-device-size";
import "./patient-starter-kit.scss";
import bannerImg from "../images/patient-starter-kit/starter-kit-header-desktop.webp";
import bannerImgMobile from "../images/patient-starter-kit/starter-kit-header-mobile.webp";
import cardImg1 from "../images/patient-starter-kit/patient-brochure.webp";
import cardImg2 from "../images/patient-starter-kit/tips-card.webp";
import cardImg3 from "../images/patient-starter-kit/getting-started.webp";
import cardImg4 from "../images/patient-starter-kit/pregnancy-registry.webp";
import cardImg5 from "../images/patient-starter-kit/w4u-consumer-process-overview.webp";
import heroName from "../images/patient-starter-kit/hero-starter-kit-name.webp";

const StartTheConversation = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout
            pageClass="patient-starter-kit orphan-page"
            jobCode="US-WAK-2400428/Aug 2024"
            copyRightYear="2024"
            noStickyHCPLocatorCTA={true}
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover gray-bg">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                alt="Kristen, a real person with narcolepsy taking WAKIX, outdoors"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                alt="Kristen, a real person with narcolepsy taking WAKIX, outdoors"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img
                                    src={heroName}
                                    alt="Kristen - Living with narcolepsy Taking WAKIX since 2020"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="inner-first no-pb">
                <div className="centered-content">
                    <div className="page-heading ph-btm">
                        <h1 className="font-40">Getting Started With WAKIX</h1>
                        <h2>
                            Download educational resources and information to
                            help as you get started with WAKIX
                        </h2>
                        <p>
                            Remember that the materials below do not take the
                            place of talking with your healthcare provider.
                            Always talk to your healthcare provider about how
                            you are feeling while taking WAKIX.
                        </p>
                    </div>

                    <div className="starter-content">
                        <div className="card-info__wrap">
                            <h3 className="mobile">
                                WAKIX Educational Brochure
                            </h3>

                            <div className="card-info">
                                <div className="card-info__media">
                                    <img
                                        src={cardImg1}
                                        alt="Patient Brochure"
                                        className="first-img"
                                    />
                                </div>
                                <div className="card-info__content">
                                    <h3>WAKIX Educational Brochure</h3>
                                    <p>
                                        An introduction to WAKIX for adults
                                        living with narcolepsy including how
                                        it's thought to work, clinical trial
                                        results, how it should be taken, and
                                        possible side effects.
                                    </p>
                                    <div className="btn-wrap">
                                        <a
                                            href="/pdf/WAKIX-educational-brochure-English.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                        >
                                            Download
                                            <svg
                                                id="download_arrow"
                                                data-name="download arrow"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                width="21"
                                                height="22"
                                                viewBox="0 0 21 22"
                                            >
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path
                                                            id="Clip_4"
                                                            data-name="Clip 4"
                                                            d="M0,0H2V16H0Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <path
                                                    id="Fill_1"
                                                    data-name="Fill 1"
                                                    d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                    transform="translate(0 17)"
                                                    fill="#fff"
                                                />
                                                <g
                                                    id="Group_5"
                                                    data-name="Group 5"
                                                    transform="translate(9)"
                                                >
                                                    <path
                                                        id="Clip_4-2"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_5-2"
                                                        data-name="Group 5"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Fill_3"
                                                            data-name="Fill 3"
                                                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </g>
                                                <path
                                                    id="Fill_6"
                                                    data-name="Fill 6"
                                                    d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                    transform="translate(3 9)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="mobile">
                                An introduction to WAKIX for adults living with
                                narcolepsy including how it's thought to work,
                                clinical trial results, how it should be taken,
                                and possible side effects.
                            </p>
                        </div>

                        <div className="card-info__wrap">
                            <h3 className="mobile">
                                Tips for Adults Living With Narcolepsy
                            </h3>

                            <div className="card-info">
                                <div className="card-info__media">
                                    <img src={cardImg2} alt="Tips Card" />
                                </div>
                                <div className="card-info__content">
                                    <h3>
                                        Tips for Adults Living With Narcolepsy
                                    </h3>
                                    <p>
                                        Discover helpful tips for navigating
                                        life with narcolepsy.
                                    </p>
                                    <div className="btn-wrap">
                                        <a
                                            href="/pdf/tips-for-living-with-narcolepsy.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                        >
                                            Download
                                            <svg
                                                id="download_arrow"
                                                data-name="download arrow"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                width="21"
                                                height="22"
                                                viewBox="0 0 21 22"
                                            >
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path
                                                            id="Clip_4"
                                                            data-name="Clip 4"
                                                            d="M0,0H2V16H0Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <path
                                                    id="Fill_1"
                                                    data-name="Fill 1"
                                                    d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                    transform="translate(0 17)"
                                                    fill="#fff"
                                                />
                                                <g
                                                    id="Group_5"
                                                    data-name="Group 5"
                                                    transform="translate(9)"
                                                >
                                                    <path
                                                        id="Clip_4-2"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_5-2"
                                                        data-name="Group 5"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Fill_3"
                                                            data-name="Fill 3"
                                                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </g>
                                                <path
                                                    id="Fill_6"
                                                    data-name="Fill 6"
                                                    d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                    transform="translate(3 9)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="mobile">
                                Discover helpful tips for navigating life with
                                narcolepsy.
                            </p>
                        </div>

                        <div className="card-info__wrap">
                            <h3 className="mobile">
                                Starting and Staying on Track With WAKIX
                            </h3>

                            <div className="card-info">
                                <div className="card-info__media">
                                    <img
                                        src={cardImg3}
                                        alt="Starting and Staying on Track With WAKIX"
                                    />
                                </div>
                                <div className="card-info__content">
                                    <h3>
                                        Starting and Staying on Track With WAKIX
                                    </h3>
                                    <p>
                                        Important information about what to
                                        expect as you begin your treatment with
                                        WAKIX.
                                    </p>
                                    <div className="btn-wrap">
                                        <a
                                            href="/pdf/WAKIX-what-to-expect-when-starting-staying-on-track-with-treatment.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                        >
                                            Download
                                            <svg
                                                id="download_arrow"
                                                data-name="download arrow"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                width="21"
                                                height="22"
                                                viewBox="0 0 21 22"
                                            >
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path
                                                            id="Clip_4"
                                                            data-name="Clip 4"
                                                            d="M0,0H2V16H0Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <path
                                                    id="Fill_1"
                                                    data-name="Fill 1"
                                                    d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                    transform="translate(0 17)"
                                                    fill="#fff"
                                                />
                                                <g
                                                    id="Group_5"
                                                    data-name="Group 5"
                                                    transform="translate(9)"
                                                >
                                                    <path
                                                        id="Clip_4-2"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_5-2"
                                                        data-name="Group 5"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Fill_3"
                                                            data-name="Fill 3"
                                                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </g>
                                                <path
                                                    id="Fill_6"
                                                    data-name="Fill 6"
                                                    d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                    transform="translate(3 9)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="mobile">
                                Important information about what to expect as
                                you begin your treatment with WAKIX.
                            </p>
                        </div>
                        <div className="card-info__wrap">
                            <h3 className="mobile">WAKIX Pregnancy Registry</h3>
                            <div className="card-info">
                                <div className="card-info__media">
                                    <img
                                        src={cardImg4}
                                        alt="WAKIX Pregnancy Registry  "
                                    />
                                </div>
                                <div className="card-info__content">
                                    <h3>WAKIX Pregnancy Registry</h3>
                                    <p>
                                        If you are pregnant or become pregnant
                                        while taking WAKIX, learn about this
                                        voluntary registry and how to enroll.
                                    </p>
                                    <div className="btn-wrap">
                                        <a
                                            href="/pdf/WAKIX-pregnancy-registry-info.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                        >
                                            Download
                                            <svg
                                                id="download_arrow"
                                                data-name="download arrow"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                width="21"
                                                height="22"
                                                viewBox="0 0 21 22"
                                            >
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path
                                                            id="Clip_4"
                                                            data-name="Clip 4"
                                                            d="M0,0H2V16H0Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <path
                                                    id="Fill_1"
                                                    data-name="Fill 1"
                                                    d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                    transform="translate(0 17)"
                                                    fill="#fff"
                                                />
                                                <g
                                                    id="Group_5"
                                                    data-name="Group 5"
                                                    transform="translate(9)"
                                                >
                                                    <path
                                                        id="Clip_4-2"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_5-2"
                                                        data-name="Group 5"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Fill_3"
                                                            data-name="Fill 3"
                                                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </g>
                                                <path
                                                    id="Fill_6"
                                                    data-name="Fill 6"
                                                    d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                    transform="translate(3 9)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="mobile">
                                If you are pregnant or become pregnant while
                                taking WAKIX, learn about this voluntary
                                registry and how to enroll.
                            </p>
                        </div>
                        <div className="card-info__wrap">
                            <h3 className="mobile">
                                <strong>WAKIX for You</strong> Overview{" "}
                            </h3>
                            <div className="card-info">
                                <div className="card-info__media">
                                    <img
                                        src={cardImg5}
                                        alt="WAKIX for You Overview  "
                                    />
                                </div>
                                <div className="card-info__content">
                                    <h3>
                                        <strong>WAKIX for You</strong> Overview{" "}
                                    </h3>
                                    <p>
                                        Overview of the{" "}
                                        <strong>WAKIX for You</strong> patient
                                        support program.
                                    </p>
                                    <div className="btn-wrap">
                                        <a
                                            href="/pdf/WAKIX-for-you-patient-support-program.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                        >
                                            Download
                                            <svg
                                                id="download_arrow"
                                                data-name="download arrow"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                width="21"
                                                height="22"
                                                viewBox="0 0 21 22"
                                            >
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path
                                                            id="Clip_4"
                                                            data-name="Clip 4"
                                                            d="M0,0H2V16H0Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <path
                                                    id="Fill_1"
                                                    data-name="Fill 1"
                                                    d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                    transform="translate(0 17)"
                                                    fill="#fff"
                                                />
                                                <g
                                                    id="Group_5"
                                                    data-name="Group 5"
                                                    transform="translate(9)"
                                                >
                                                    <path
                                                        id="Clip_4-2"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                    <g
                                                        id="Group_5-2"
                                                        data-name="Group 5"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Fill_3"
                                                            data-name="Fill 3"
                                                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </g>
                                                <path
                                                    id="Fill_6"
                                                    data-name="Fill 6"
                                                    d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                    transform="translate(3 9)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="mobile">
                                Overview of the <strong>WAKIX for You</strong>{" "}
                                patient support program.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </HcpLayout>
    );
};

export default StartTheConversation;

export const Head = () => (
    <Seo
        title="Patient Starter Kit | WAKIX® (pitolisant) tablets"
        description=""
        keywords=""
    />
);
